import Layout from "components/layout";
import HelpvEverybody from "components/wordCountRatio/HelpEverybody";
import React from "react";
import Seo from "components/seo";

const index = () => {
  return (
    <Layout>
      <Seo
        title="Help Everybody - Word Count Ratio Tool"
        slug="/word-count-ratio-tool/helpeverybody"
      />
      <HelpvEverybody />
    </Layout>
  );
};

export default index;
